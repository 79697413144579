import type { TBackgroundColor, TCurrencyType, TPossibleNull } from '~/types/Shared.types';

// Данные о событии для кейса
export interface ISharedKitCaseEvent {
  color: TBackgroundColor;
  icon: string;
}

// Данные о стоимости
interface ISharedKitCaseDataPrice {
  coins: number;
  currency: TCurrencyType;
  old: number;
  price: number;
}

// Данные о счётчике
export interface ISharedKitCaseDataCounter {
  end: number;
  start: number;
  type: 'quantity' | 'time';
}

// Данные о лейбле кейса
export interface ISharedKitCaseDataLabel {
  background: TBackgroundColor;
  color: TBackgroundColor;
  text?: string;
}

// Данные о кейсе
export interface ISharedKitCaseData {
  coinsData?: TPossibleNull<number[] | number>;
  counterData?: TPossibleNull<ISharedKitCaseDataCounter>;
  counterEnd?: string;
  counterStart?: string;
  image: string;
  labelData: TPossibleNull<ISharedKitCaseDataLabel>;
  link: string;
  name: string;
  priceData: Partial<ISharedKitCaseDataPrice>;
}

// Данные о цветах кейса
export interface ISharedKitCaseDefaultColors {
  background: TBackgroundColor;
  circle: TBackgroundColor;
  hover: TBackgroundColor;
  shadow: TBackgroundColor;
}

export enum ESharedKitCaseTypes {
  BATTLE = 'battle',
  BATTLE_SELECTED = 'battle-selected',
  DEFAULT = 'default',
}

// Вариант дефолтного кейса
export interface ISharedKitCaseDefault {
  colorData?: ISharedKitCaseDefaultColors;
  locked?: boolean;
  showRag?: boolean;
  type: ESharedKitCaseTypes.DEFAULT;
}

// Вариант кейса для батлов
export interface ISharedKitCaseBattle {
  selected: number;
  showRag?: boolean;
  type: ESharedKitCaseTypes.BATTLE_SELECTED | ESharedKitCaseTypes.BATTLE;
}

export interface ISharedKitCaseProps {
  caseData: ISharedKitCaseData;
  disabled?: boolean;
  eventData: ISharedKitCaseEvent;
  isSmallSize?: boolean;
  variant: ISharedKitCaseDefault | ISharedKitCaseBattle;
}

export interface ISharedKitCaseEmits {
  (e: 'remove'): void;

  (e: 'minus'): void;

  (e: 'plus'): void;

  (e: 'add'): void;
}
